<template>
  <div class="bt-transaksi pa-5">
    <h6
      class="text-h5 font-weight-bold orange--text text--lighten-1 d-flex align-center"
    >
      <div
        class="rounded-pill orange lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3"
      >
        <v-icon small color="white">mdi-account-multiple-minus</v-icon>
      </div>
      Registrasi Anggot Keluar
    </h6>
    <v-row class="mt-5">
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Rembug</label>
        <v-select
          color="black"
          v-model="form.data.cm_code"
          :items="opt.rembug"
          @change="getAnggota()"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Anggota</label>
        <v-select
          color="black"
          v-model="form.data.cif_no"
          :items="opt.anggota"
          @change="setProfile()"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">No Anggota</label>
        <v-text-field
          color="black"
          :value="form.data.cif_no"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Nama</label>
        <v-text-field
          color="black"
          :value="profile.nama"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Tanggal Mutasi</label>
        <v-text-field
          color="black"
          v-model="form.data.tanggal_mutasi"
          type="date"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Alasan</label>
        <v-select
          color="black"
          v-model="form.data.alasan"
          :items="opt.alasan"
          hide-details
          flat
          solo
          dense
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Keterangan</label>
        <v-textarea
          color="black"
          v-model="form.data.keterangan"
          autocomplete="false"
          hide-details
          flat
          solo
          dense
          rows="3"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Pembiayaan</label>
        <vue-numeric
          v-model="form.data.saldo_pembiayaan"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Margin </label>
        <v-row>
          <v-col cols="10">
            <vue-numeric
              v-model="form.data.saldo_margin"
              currency="Rp "
              separator="."
              class="white w-100 pa-2"
              disabled
            />
          </v-col>
          <v-col>
            <v-checkbox
              v-model="form.data.flag_saldo_margin"
              hide-details
              flat
              solo
              dense
              @change="countHutang()"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Catab</label>
        <vue-numeric
          v-model="form.data.saldo_catab"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Tabungan Wajib</label>
        <vue-numeric
          v-model="form.data.saldo_tabungan_wajib"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Tabungan Kelompok</label>
        <vue-numeric
          v-model="form.data.saldo_tabungan_kelompok"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Sukarela</label>
        <vue-numeric
          v-model="form.data.saldo_sukarela"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Simpanan Wajib</label>
        <vue-numeric
          v-model="form.data.saldo_simpanan_wajib"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Tabungan Berencana</label>
        <vue-numeric
          v-model="form.data.saldo_tabungan_berencana"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Tabungan Individu</label>
        <vue-numeric
          v-model="form.data.saldo_individu"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Deposito</label>
        <vue-numeric
          v-model="form.data.saldo_deposito"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Simpanan Pokok</label>
        <vue-numeric
          v-model="form.data.simpanan_pokok"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo LWK</label>
        <vue-numeric
          v-model="form.data.saldo_lwk"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Tabungan Majelis</label>
        <vue-numeric
          v-model="form.data.saldo_tabungan_majelis"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo SMK</label>
        <vue-numeric
          v-model="form.data.saldo_smk"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Bonus Bagi Hasil</label>
        <vue-numeric
          v-model="form.data.bonus_bagihasil"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12">
        <hr/>
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Hutang</label>
        <vue-numeric
          v-model="form.data.saldo_hutang"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Potongan Pembiayaan</label>
        <vue-numeric
          v-model="form.data.potongan_pembiayaan"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          @change="countHutang()"
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Saldo Simpanan</label>
        <vue-numeric
          v-model="form.data.saldo_simpanan"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
      <v-col cols="12" class="py-0 mb-3">
        <label for="">Setoran Tambahan</label>
        <vue-numeric
          v-model="form.data.setoran_tambahan"
          currency="Rp "
          separator="."
          class="white w-100 pa-2"
          disabled
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-center mt-4 pt-4">
      <v-btn class="orange lighten-1 white--text mx-2" @click="$router.push(`/keanggotaan`)">Kembali</v-btn>
      <v-btn class="orange lighten-1 white--text mx-2" @click="prosesKeluar()"> Simpan </v-btn>
    </div>
    <v-snackbar v-model="alert.show" :timeout="3000">
      {{ alert.msg }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
export default {
  name: "KeangotaanKeluar",
  components: {
  },
  data() {
    return {
      profile: Object,
      form: {
        data: {
          cif_no: null,
          cm_code: null,
          fa_code: null,
          tipe_mutasi: 1,
          tanggal_mutasi: null,
          alasan: null,
          keterangan: null,
          saldo_pembiayaan: 0,
          saldo_margin: 0,
          flag_saldo_margin: true,
          saldo_catab: 0,
          saldo_tabungan_wajib: 0,
          saldo_tabungan_kelompok: 0,
          saldo_sukarela: 0,
          saldo_simpanan_wajib: 0,
          saldo_tabungan_berencana: 0,
          saldo_individu: 0,
          saldo_deposito: 0,
          simpanan_pokok: 0,
          saldo_lwk: 0,
          saldo_tabungan_majelis: 0,
          saldo_smk: 0,
          saldo_cadangan_resiko: 0,
          bonus_bagihasil: 0,
          potongan_pembiayaan: 0,
          setoran_tambahan: 0,
          penarikan_tabungan_sukarela: 0,
          saldo_hutang: 0,
          saldo_simpanan: 0
        }
      },
      opt: {
        alasan: [
          {value: 1, text: "Meninggal"},
          {value: 2, text: "Karakter"},
          {value: 3, text: "Pindah Lembaga Lain"},
          {value: 4, text: "Tidak diijinkan Suami"},
          {value: 5, text: "Saving Kurang"},
          {value: 6, text: "Kondisi Keluarga"},
          {value: 7, text: "Pindah Alamat"},
          {value: 8, text: "Tidak Setuju Keputusan Lembaga"},
          {value: 9, text: "Usia / Jompo"},
          {value: 10, text: "Sakit"},
          {value: 11, text: "Majelis Bubar"},
          {value: 12, text: "Tidak Punya Waktu"},
          {value: 13, text: "Kerja"},
          {value: 14, text: "Cerai"},
          {value: 15, text: "Pembiayaan Bermasalah"},
          {value: 16, text: "Usaha Sdh Berkembang"},
          {value: 17, text: "Tidak Mau Kumpulan"},
          {value: 18, text: "Batal Pembiayaan (Anggota baru)"},
          {value: 19, text: "Migrasi Anggota Individu"}
        ],
        rembug: [],
        anggota: [],
      },
      alert: {
        show: false,
        msg: null,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getRembug() {
      let day = new Date().getDay();
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("day", day);
      // payload.append("day", 1);
      try {
        let req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.opt.rembug = [];
          if(data){
            data.map((item) => {
              let opt = {
                value: item.cm_code,
                text: item.cm_name,
                data: item,
              };
              this.opt.rembug.push(opt);
            });
          }
        } else {
          this.notif('Gagal mendapatkan data Rembug')
        }
      } catch (error) {
        this.notif(error)
      }
    },
    async getAnggota() {
      let payload = new FormData();
      payload.append("cm_code", this.form.data.cm_code);
      try {
        let req = await services.infoMember(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.opt.anggota = [];
          data.map((item) => {
            let opt = {
              value: item.cif_no,
              text: item.nama,
              data: item,
            };
            this.opt.anggota.push(opt);
          });
        } else {
          this.notif('Gagal mendapatkan data Anggota')
        }
      } catch (error) {
        this.notif(error)
      }
    },
    async getDetil() {
      let payload = `cif_no=${this.form.data.cif_no}`
      try {
        let req = await services.detilAnggotaKeluar(payload, this.user.token);
        if (req.status === 200) {
          let { data } = req.data;
          this.form.data = {
            ...data
          }
          this.form.data.flag_saldo_margin = true
          this.countHutang()
        } else {
          this.notif('Gagal mendapatkan detil Anggota')
        }
      } catch (error) {
        this.notif(error)
      }
    },
    setProfile() {
      let profile = this.opt.anggota.find(
        (item) => item.value == this.form.data.cif_no
      ).data;
      this.profile = profile
      this.getDetil()
    },
    countHutang() {
      if(this.form.data.flag_saldo_margin){
        this.form.data.saldo_hutang = Number(this.form.data.saldo_pembiayaan) + Number(this.form.data.saldo_margin)
      } else {
        this.form.data.saldo_hutang = this.form.data.saldo_pembiayaan
      }
      if(this.form.data.potongan_pembiayaan && Number(this.form.data.potongan_pembiayaan) > 0){
        this.form.data.saldo_hutang = Number(this.form.data.saldo_hutang) - Number(this.form.data.potongan_pembiayaan)
      } else {
        this.form.data.potongan_pembiayaan = 0
      }
      this.form.data.setoran_tambahan = Number(this.form.data.saldo_simpanan) - Number(this.form.data.saldo_hutang)
    },
    async prosesKeluar() {
      let payload = new FormData()
      let payloadData = {...this.form.data}
      payloadData.fa_code = this.user.fa_code
      payloadData.flag_saldo_margin = (payloadData.flag_saldo_margin) ? 1 : 2
      for(let key in payloadData){
        payload.append(key,payloadData[key])
      }
      try {
        let req = await services.registrasiAnggotaKeluar(payload, this.user.token);
        let {data,msg,status} = req.data
        console.log(req.data)
        if (req.status === 200 && status) {
          this.notif('Registrasi Anggota Keluar berhasil dibuat')
          setTimeout(() => {
            this.$router.push(`/keanggotaan`);
          },1000)
        } else {
          this.notif(msg)
        }
      } catch (error) {
        this.notif(error)
      }
    },
    generateDate(){
      var dateObj = new Date();
      var month = String(dateObj.getUTCMonth() + 1).padStart(2, '0')
      var day = String(dateObj.getUTCDate()).padStart(2, '0')
      var year = dateObj.getUTCFullYear();
      var hours = dateObj.getHours()
      var minutes = dateObj.getMinutes()
      var seconds = dateObj.getSeconds()
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    notif(msg) {
      this.alert = {
        show: true,
        msg: msg,
      };
    },
  },
  mounted() {
    this.getRembug();
  },
};
</script>
